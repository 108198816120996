import React from 'react'

import styled, { css } from 'styled-components'

import settings from '../../../kenra-storybook/global/settings'
import * as SharedStyles from '../../../kenra-storybook/global/SharedStyles'
const { StLinkMore } = SharedStyles
import CustomLink from 'components/Link'

const StMasonaryColumn = styled.section.attrs(props => ({
    className: 'StMasonaryColumn',
}))`
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    width: 100%;

    @media (min-width: ${settings.bp.medium.view}) {
        width: 32%;
    }

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;
        text-align: center;

        @media (min-width: ${settings.bp.medium.view}) {
            line-height: 1.79;
            margin: 0 0 20px;
            ${props =>
        props.smaller &&
        css`
                @media (min-width: ${settings.bp.small.view}) {
                    margin-top: 0px;
                }
                `};
        }
    }


    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 18px;
            letter-spacing: 0.08em;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.small.view}) {
        //margin-top: 24px;
    }

    // background-color: red;
    // border: 2px solid black;
    ${props =>
        props.smaller &&
        css`
        @media (max-width: ${settings.bp.small.view}) {
        h2 {
            font-size: 18.44px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 120% !important;
            letter-spacing: 1.317px;
            text-transform: uppercase;
        }
        }
        `};
`

const StMasonaryItemImg = styled.div.attrs(props => ({
    className: 'StMasonaryItemImg',
}))`
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    @media (max-width: ${settings.bp.small.view}) {
        padding-left: 0px;
        padding-right: 0px;
    }

    img {
        max-width: 100%;
        object-fit: contain;
        @media (min-width: ${settings.bp.small.view}) {
            max-height: 900px;
            ${props =>
        props.smaller &&
        css`
                max-width: 80%;
                @media (min-width: ${settings.bp.small.view}) {
                  max-height: 600px;
                }
                `};
        }
    }
`

const StMasonaryItemInternalImg = styled.img.attrs(props => ({
    className: 'StMasonaryItemInternalImg',
}))`
    width: 100%;
`

const StBlockContainer = styled.div.attrs(props => ({
    className: 'StBlockContainer',
}))`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    @media (min-width: ${settings.bp.medium.view}) {
        flex-direction: row;
    }
`


function Item(props) {
    const { image, slideLinkText, slideLink } = props

    return (
        <StMasonaryColumn>
            {image?.localFile?.big?.fluid?.src && (
                <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px;' }} href={slideLink}>
                    <StMasonaryItemImg>
                        <StMasonaryItemInternalImg src={image?.localFile?.big?.fluid?.src} />
                    </StMasonaryItemImg>
                </CustomLink>
            )}

            {slideLinkText && slideLink && (
                <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px;', 'paddingLeft': '24px' }} href={slideLink}>
                    <h3 style={{ fontWeight: 500, paddingLeft: '18px', paddingTop: '6px' }}>{slideLinkText}</h3>
                </CustomLink>
            )}
        </StMasonaryColumn>
    )
}

export default function Blocks(props) {
    const { items } = props
    if (!items) {
        return null
    }
    return (<StBlockContainer>
        {items.map((item, index) => {
            if (index === 0) return null
            return <Item key={index} {...item} />
        })}
    </StBlockContainer>)
}
